export const PreloadSounds = (p) => {
  p.soundFormats('mp3', 'ogg', 'wav')
  // ეს რო იყოს იდეაში ადგილი სადაც სრულად აღვწერთ character-ებს თავისი
  // ხმით, ბექგრაუნდით და ტექსტით.
  return {
    kick1: {
      sound: p.loadSound(require('url:../../assets/sound/kick_1.wav')),
      effects: {},
      el: null,
      playing: false
    },
    kick2: {
      sound: p.loadSound(require('url:../../assets/sound/kick_2.wav')),
      effects: {},
      el: null,
      playing: false
    },
    snare: {
      sound: p.loadSound(require('url:../../assets/sound/snare.wav')),
      effects: {},
      el: null,
      playing: false
    },
    rim: {
      sound: p.loadSound(require('url:../../assets/sound/rim-shot.wav')),
      effects: {},
      el: null,
      playing: false
    },
    'hat-closed': {
      sound: p.loadSound(require('url:../../assets/sound/hat-closed.wav')),
      effects: {},
      el: null,
      playing: false
    },
    'hat-open': {
      sound: p.loadSound(require('url:../../assets/sound/hat-open.wav')),
      effects: {},
      el: null,
      playing: false
    },
    'tom-high': {
      sound: p.loadSound(require('url:../../assets/sound/tom-high_v1.wav')),
      effects: {},
      ref: null,
      playing: false
    },
    'tom-low': {
      sound: p.loadSound(require('url:../../assets/sound/tom-low.wav')),
      effects: {},
      el: null,
      playing: false
    },
    cymbal: {
      sound: p.loadSound(require('url:../../assets/sound/cymbal.wav')),
      effects: {},
      el: null,
      playing: false
    },
    maracas: {
      sound: p.loadSound(require('url:../../assets/sound/maracas.wav')),
      effects: {},
      el: null,
      playing: false
    },
    zap: {
      sound: p.loadSound(require('url:../../assets/sound/zap.wav')),
      effects: {},
      el: null,
      playing: false
    },
    sample: {
      sound: p.loadSound(require('url:../../assets/sound/zap_v1.wav')),
      effects: {},
      el: null,
      playing: false
    }
  }
}
