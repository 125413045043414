'use strict'

export const About = {
  props: {
  },

  Flex: {
    props: {
      flow: 'column',
      position: 'relative',
      zIndex: 1,
      width: '100%',
      maxWidth: 'I3',
      margin: 'auto',
      align: 'center center',
      textAlign: 'center'
    },

    H3: {
      text: 'The monthly party at ://about blank is breaking with tradition by not announcing lineups.'
    },

    P: {
      text: 'How STAUB, A “Techno Communist” Day Party, Quietly Became One Of Berlin’s Best Events'
    },

    Img: {
      margin: 'C1',
      maxWidth: '100%',
      src: 'https://res.cloudinary.com/electronic-beats/c_fit,q_auto,f_auto,w_3840/stage/uploads/2019/02/STAUB-lead-image-by-george-nebieridze.jpg'
    },

    Text: {
      margin: 'A',
      fontFamily: 'ui',
      text: 'Published February 20, 2019. Words by Chloé Lula, photos by George Nebieridze.'
    },

    Flex: {
      props: {
        gap: 'A1',
        childProps: { flex: 1, textAlign: 'justify' }
      },
      Italic: {
        margin: 'A',
        fontFamily: 'ui',
        text: 'STAUB, a monthly daytime event that takes place the last Saturday of every month at ://about blank, is one of Berlin’s best parties. But it’s historically operated behind a shroud of anonymity; the lineups are never announced, the STAUB Records artists are never revealed and the promoters skirt the sidelines of the dance floors, never putting themselves in the limelight.'
      },

      Italic_2: {
        margin: 'A',
        fontFamily: 'ui',
        text: 'In honor of the party’s six-year anniversary this weekend, we decided to take a deeper look at its impact on the scene. TEB editor Chloé Lula traveled to Detroit to see STAUB’s United States debut and discussed the party’s philosophy with its three organizers, Irakli, Ines and Jan (pictured above).'
      }
    },

    Divider: {},

    P_2: {
      fontFamily: 'ui',
      text: 'It’s twilight on Monday, May 28 and the sun is setting over the Detroit skyline. A tired but exuberant crowd is dancing on the back patio of the downtown venue TV Lounge, an off-Movement Festival space that plays host to afterparties throughout the duration of the country’s largest and oldest electronic music event. But on this final day of the Memorial Day weekend, the crowd isn’t dancing to the more standard musical offerings of local talent or US-based techno artists; they’re dancing to STAUB, a Berlin-based party that only recently touched down in Detroit.'
    },

    P_3: {
      fontFamily: 'ui',
      text: 'STAUB’s appearance in the Motor City was its first in the US, and its famously anonymous booking concept fell counter to the heavily promoted Movement festivities that were happening a few blocks away. That the undisclosed STAUB headliners included major acts that played at the much more heavily publicized—and costly—festival the weekend before was not received without a touch of irony from the guests who were able to identify them.'
    },

    Flex_soundcloud: {
      props: {
        width: '100%',
        margin: 'C',
        gap: 'Z',
        flow: 'column'
      },

      Iframe: {
        width: '100%',
        minHeight: 'none',
        height: '166px',
        scrolling: 'no',
        frameborder: 'no',
        allow: 'autoplay',
        src: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/576853176&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'
      },

      Italic: {
        fontSize: 'Z1',
        fontFamily: 'ui',
        text: 'Lorem inpsum from the guests who were able to identify them.'
      }
    }
  }
}

export const AboutWithBackground = {
  props: {
    ':before': {
      content: '""',
      opacity: '.35',
      position: 'absolute',
      zIndex: 0,
      inset: '0 0 0 0',
      background: 'url(https://res.cloudinary.com/electronic-beats/c_fit,q_auto,f_auto,w_3840/stage/uploads/2019/02/STAUB-lead-image-by-george-nebieridze.jpg) center center',
      backgroundSize: 'cover'
    },
    ':after': {
      content: '""',
      opacity: '.35',
      position: 'absolute',
      zIndex: 0,
      inset: '0 0 0 0',
      background: 'dark 1 -250'
    }
  },

  Flex: {
    props: {
      flow: 'column',
      position: 'relative',
      zIndex: 1,
      width: '100%',
      maxWidth: 'I3',
      margin: 'auto',
      align: 'center center',
      textAlign: 'center'
    },

    H3: {
      text: 'The monthly party at ://about blank is breaking with tradition by not announcing lineups.'
    },

    P: {
      text: 'How STAUB, A “Techno Communist” Day Party, Quietly Became One Of Berlin’s Best Events'
    }
  }
}
