'use strict'

import p5 from 'p5'
import { PreloadSounds } from './preload'

const makeSound = (soundObj) => {
  return function (time, playbackRate) {
    soundObj.el.target.classList.add('active')
    setTimeout(() => {
      soundObj.el.target.classList.remove('active')
    }, 350)
    soundObj.sound.rate(playbackRate)
    soundObj.sound.play(time)
  }
}

let looping = false
let sequence
let currentPart
let activePhrase
let phraseName
let sounds
let activePad = null

const phrases = {}
const patterns = {}

export const DrumMachine = {
  preload: p => {
    sounds = PreloadSounds(p)
  },

  setup: (p) => {
    const startButton = p.select('.play-button')
    const stopButton = p.select('.stop-button')
    const clearButton = p.select('.clear-button') // eslint-disable-line
    const sequencerSteps = p.selectAll('.sequencer-step')
    const pacman = p.select('#pacman')
    const pads = p.selectAll('.drum-pad')
    const instrumentParts = [
      'kick1',
      'kick2',
      'snare',
      'rim',
      'hat-closed',
      'hat-open',
      'tom-high',
      'tom-low',
      'cymbal',
      'maracas',
      'zap',
      'sample'
    ]

    instrumentParts.forEach(el => {
      patterns[el] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      phrases[el] = new p5.Phrase(el, makeSound(sounds[el]), patterns[el])

      sounds[el].effects.lowPassfilter = new p5.LowPass()
      sounds[el].effects.highPassfilter = new p5.HighPass()
      sounds[el].effects.delay = new p5.Delay()
      sounds[el].effects.reverb = new p5.Reverb()
      // sounds[el].sound.disconnect()
      // sounds[el].sound.connect(sounds[el].effects.env);
      sounds[el].effects.reverb.process(sounds[el].sound, 0.1, 1, 0)
      sounds[el].effects.delay.process(sounds[el].sound, 0, 0, 0)
      // sounds[el].sound.connect(sounds[el].effects.reverb);
      sounds[el].sound.connect(sounds[el].effects.lowPassfilter)
      sounds[el].sound.connect(sounds[el].effects.highPassfilter)
      sounds[el].sound.connect(sounds[el].effects.delay)

      // sounds[el].effects.reverb.set(0, 0);
      // sounds[el].effects.delay.filter(0);
      // sounds[el].effects.delay.delayTime(0.1);
      // sounds[el].effects.delay.feedback(0.1);
      // sounds[el].effects.highPassfilter.amp(sounds[el].effects.env);
      // sounds[el].sound.amp(sounds[el].effects.env);
    })

    // Initialize a new p5.Part object
    currentPart = new p5.Part(16)
    currentPart.setBPM(80)
    let stepCounter = 0
    const pacmanPhrase = new p5.Phrase(
      'pacman',
      () => {
        // move pacman to next step
        pacman.style('left', `${stepCounter * 42}px`)
        stepCounter++
        if (stepCounter > 15) {
          stepCounter = 0
        }
      },
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
    )
    // start stop button for the part
    startButton.mouseClicked(function () {
      currentPart.loop()
      currentPart.addPhrase(pacmanPhrase)
      if (currentPart != null) {
        currentPart.start()
        currentPart.loop()
        looping = true
        // this.addClass('active')
      }
    })
    stopButton.mouseClicked(function () {
      if (currentPart != null) {
        currentPart.stop()
        looping = false
        startButton.removeClass('active')
      }
    })

    // Handle sequencer steps
    sequencerSteps.forEach((el, i) =>
      el.mouseClicked(function () {
        sequence = activePhrase.sequence
        if (activePhrase.sequence[i]) {
          sequence[i] = 0
          currentPart.replaceSequence(activePhrase.name, sequence)
          el.removeClass('active')
        } else {
          sequence[i] = 1
          currentPart.replaceSequence(activePhrase.name, sequence)
          el.addClass('active')
        }
      })
    )

    pads.forEach((pad) =>
      pad.mouseClicked(function (el) {
        phraseName = pad.attribute('id')
        // Add el to sound object
        if (sounds[phraseName].el == null) {
          sounds[phraseName].el = el
        }
        sounds[phraseName].sound.play()
        pads.forEach(p => p.removeClass('recording'))
        pad.addClass('recording')
        if (
          currentPart.getPhrase(phraseName) == null &&
          phrases[phraseName] != null
        ) {
          currentPart.addPhrase(phrases[phraseName])
          // if (looping) {
          pad.addClass('playing')
          // }
          activePad = pad
        } else if (activePad === pad) {
          currentPart.removePhrase(phraseName)
          // if (looping) {
          pad.removeClass('recording')
          pad.removeClass('playing')
          // }
          activePad = null
        } else {
          activePad = pad
        }

        if (activePad != null) {
          activePhrase = currentPart.getPhrase(activePad.attribute('id'))
          sequencerSteps.forEach((el, i) => {
            el.removeClass('active')
            if (activePhrase.sequence[i]) {
              el.addClass('active')
            }
          })
        } else {
          sequencerSteps.forEach((el) => el.removeClass('active'))
        }
      })
    )
  }
}
