'use strict'

import { About } from './About'
import { Main } from './Main'
// import { Controller } from './Controller'

export default {
  '/': Main,
  // '/controller': Controller,
  '/events': { text: 'events' },
  '/about': About,
  '/contact': { text: 'contact' }
}
