'use strict'

export default {
  main: {
    data: {
      looping: false,
      myPart: {},
      sounds: {},
      phrases: {},
      patterns: {}
    }
  }
}
