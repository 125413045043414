'use strict'

import { Link } from 'smbls'
import { TransitionTrigger } from './Transition'

export const Navigation = {
  extend: [TransitionTrigger, 'Flex'],

  props: () => {
    let location = window.location.pathname.split('/')[1]
    let fontFamily = 'ui'
    if (location == 'controller') {
      fontFamily = 'game'
      console.log('controller')
    }
    return {
      gap: 'D3',
      zIndex: 2,
      pointerEvents: 'none',
      order: 3,
      padding: 'C1',
      fontFamily: fontFamily
    }
  },

  childExtend: {
    props: {
      color: 'charm',
      fontWeight: '300',
      letterSpacing: '3px',
      pointerEvents: 'auto'
    },
    on: {
      click: (ev, el, s) => {
        setTimeout(() => {
          Link.on.click(ev, el, s)
        }, 250)
      }
    }
  },

  $collection: [
    {
      props: {
        text: 'Home',
        href: '/'
      }
    },
    // {
    //   props: {
    //     text: 'Controller',
    //     href: '/controller'
    //   }
    // },
    {
      props: {
        text: 'Events',
        href: '/events'
      }
    },
    {
      props: {
        text: 'About',
        href: '/about'
      }
    },
    {
      props: {
        text: 'Contact',
        href: '/contact'
      }
    }
  ]
}
