'use strict'

const pacmanSvg = new URL('../assets/pacman.svg', import.meta.url)
export const Pacman = {
  props: {
    backgroundColor: 'none',
    backgroundImage: pacmanSvg.toString(),
    backgroundSize: '160px',
    backgroundPosition: '40px 0',
    width: '40px',
    height: '40px',
    borderRadius: '0',
    transform: 'translateX(-20px)',

    '.playing': {
      animation: 'pacman',
      animationDuration: '350ms',
      animationIterationCount: 'infinite'
    }
  }
}

const pinkySvg = new URL('../assets/pinky_right.svg', import.meta.url)
export const Pinky = {
  props: ({ key }) => ({
    backgroundColor: 'none',
    backgroundImage: pinkySvg.toString(),
    backgroundSize: '76px',
    backgroundPosition: '76px 0',
    width: '38px',
    height: '38px',
    borderRadius: '0',
    position: 'relative',
    id: key.split('.')[1],
    cursor: 'pointer',

    '&.active, &.recording': {
      animation: 'pinky',
      animationDuration: '350ms',
      animationIterationCount: 'infinite'
    }
  }),

  Text: ({ key }) => ({
    position: 'absolute',
    top: '110%',
    left: '50%',
    textAlign: 'center',
    width: '200%',
    whiteSpace: 'normal',
    transform: 'translate3d(-50%, 0, 1px)',
    fontSize: 'Y2',
    text: key.split('.')[1]
  })
}

const clydeSvg = new URL('../assets/clyde_up.svg', import.meta.url)
export const Clyde = {
  extend: 'Pinky',
  props: {
    backgroundImage: clydeSvg.toString()
  }
}

const blinkySvg = new URL('../assets/blinky_right.svg', import.meta.url)
export const Blinky = {
  extend: 'Pinky',
  props: {
    backgroundImage: blinkySvg.toString()
  }
}

const blinkyAngry = new URL('../assets/blinky_right_angry.svg', import.meta.url)
export const BlinkyAngry = {
  extend: 'Pinky',
  props: {
    backgroundImage: blinkyAngry.toString()
  }
}

const blinkyAnnoyed = new URL(
  '../assets/blinky_up_annoyed.svg',
  import.meta.url
)
export const BlinkyAnnoyed = {
  extend: 'Pinky',
  props: {
    backgroundImage: blinkyAnnoyed.toString()
  }
}

const inkySvg = new URL('../assets/inky_down.svg', import.meta.url)
export const Inky = {
  extend: 'Pinky',
  props: {
    backgroundImage: inkySvg.toString()
  }
}

const scaredSvg = new URL('../assets/scared_blue.svg', import.meta.url)
export const Scared = {
  extend: 'Pinky',
  props: {
    backgroundImage: scaredSvg.toString(),

    '.playing': {
      animation: 'pinky',
      animationDuration: '350ms',
      animationIterationCount: 'infinite'
    }
  }
}

const cherrySvg = new URL('../assets/cherry.svg', import.meta.url)
export const Cherry = {
  extend: 'Pinky',
  props: {
    backgroundColor: 'none',
    backgroundSize: '38px',
    backgroundPosition: '0 0',
    backgroundImage: cherrySvg.toString(),
    whiteSpace: 'nowrap',

    '&.active, &.recording': {
      animation: 'cherry',
      animationDuration: '350ms',
      animationIterationCount: 'infinite'
    }
  }
}

const bellSvg = new URL('../assets/bell.svg', import.meta.url)
export const Bell = {
  extend: 'Cherry',
  props: {
    backgroundImage: bellSvg.toString()
  }
}

const keySvg = new URL('../assets/key.svg', import.meta.url)
export const Key = {
  extend: 'Cherry',
  props: {
    backgroundImage: keySvg.toString()
  }
}

const galaxianSvg = new URL('../assets/galaxian.svg', import.meta.url)
export const Galaxian = {
  extend: 'Cherry',
  props: {
    backgroundImage: galaxianSvg.toString()
  }
}

const appleSvg = new URL('../assets/apple.svg', import.meta.url)
export const Apple = {
  extend: 'Cherry',
  props: {
    backgroundImage: appleSvg.toString()
  }
}

const melonSvg = new URL('../assets/melon.svg', import.meta.url)
export const Melon = {
  extend: 'Cherry',
  props: {
    backgroundImage: melonSvg.toString()
  }
}

const orangeSvg = new URL('../assets/orange.svg', import.meta.url)
export const Orange = {
  extend: 'Cherry',
  props: {
    backgroundImage: orangeSvg.toString()
  }
}

const strawberrySvg = new URL('../assets/strawberry.svg', import.meta.url)
export const Strawberry = {
  extend: 'Cherry',
  props: {
    backgroundImage: strawberrySvg.toString()
  }
}
