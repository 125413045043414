'use strict'

import P5 from 'p5'
import 'p5/lib/addons/p5.sound'

import { create } from 'smbls'

import designSystem from './designSystem'
import * as components from './components'
import state from './state'
import pages from './pages'

import { SpaceParticles } from './components/SpaceParticles/utils'
import { DrumMachine } from './components/Controller/utils'

create({
  extend: 'Flex',

  data: {
    preload: [DrumMachine.preload],
    setup: [SpaceParticles.setup, DrumMachine.setup],
    draw: [SpaceParticles.draw]
  },

  props: {
    theme: 'document',
    flow: 'column',
    height: '100vh',
    align: 'center space-between',
    content: {

      flow: 'column',
      flex: '1 1 auto',
      zIndex: 1,
      order: 2
    },

    '& .p5Canvas': {
      position: 'fixed',
      inset: '0 0 0 0',
      zIndex: 0
    }
  },

  // Loading: {},

  Logo: {},
  Navigation: {},
  Transition: {},
  SpaceParticles: { hide: true },

  on: {
    render: (el, s) => {
      return new P5((p) => {
        p.preload = () => el.data.preload.forEach(callback => callback(p))
        p.setup = () => el.data.setup.forEach(callback => callback(p))
        p.draw = () => el.data.draw.forEach(callback => callback(p))
      }, el.node)
    }
  }
}, {
  state,
  designSystem,
  components,
  pages
})
