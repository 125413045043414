class Particle {
  constructor (p, x, y) {
    // Position and velocity
    this.p = p
    this.reset(
      this.p.random(this.p.windowWidth),
      this.p.random(this.p.windowHeight)
    )
  }

  reset (x, y) {
    this.position = this.p.createVector(x, y)
    this.velocity = this.p.createVector(0, this.p.random(0.2, 0.2))
    this.size = this.p.random(2, 5)
    this.alpha = this.p.random(50, 150)
  }

  // Update particle's position
  update () {
    this.velocity.add(0, 0)
    this.position.add(this.velocity)

    // Optional: Add boundaries or reset logic
    // Check for boundary collision and set global flag if collision occurs
    if (
      this.position.x < 0 ||
      this.position.x > this.p.width ||
      this.position.y < 0 ||
      this.position.y > this.p.height
    ) {
      this.reset(this.p.random(this.p.windowWidth), 0)
    }
  }

  // Display the particle
  display () {
    this.p.noStroke()
    this.p.fill(255, this.alpha)
    this.p.ellipse(this.position.x, this.position.y, this.size)
  }
}

const particles = []

export const SpaceParticles = {
  setup: (p) => {
    p.createCanvas(p.windowWidth, p.windowHeight)
    for (let i = 0; i < 50; i++) {
      particles.push(
        new Particle(p, p.random(p.windowWidth), p.random(p.windowHeight))
      )
    }
  },

  draw: (p) => {
    p.background(0)
    // Update and display particles
    for (const particle of particles) {
      particle.update()
      particle.display()
    }
  }
}
