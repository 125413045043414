'use strict'

import { DrumMachine } from './utils'
import { Grid, Flex } from 'smbls'

export const Controller = {
  extend: Grid,

  props: {
    class: 'test-class',
    borderRadius: 'Z',
    gap: 'Z',
    templateColumns: '1fr 1fr 1fr 1fr 1fr',
    position: 'relative',
    margin: 'auto',
    flexDirection: 'column',
    background: 'black 0.25',
    backdropFilter: 'blur(3px)',
    zIndex: 2
  },

  childExtend: {
    props: {
      round: 'Y',
      border: '3px, solid, white .05'
    }
  },

  DrumPads: {
    extend: Grid,
    props: {
      gridColumnStart: 'span 3',
      padding: 'C D D',
      gap: 'D1',
      columns: 'repeat(4, 1fr)',
      '@tabletS': {
        gridColumnStart: 'span 5',
        columns: 'repeat(3, 1fr)'
      }
    },

    childExtend: {
      props: {
        class: 'drum-pad'
      }
    },

    'Pinky.cymbal': {},
    'Clyde.maracas': {},
    'Blinky.zap': {},
    'Inky.sample': {},
    'Cherry.hat-closed': {},
    'BlinkyAngry.hat-open': {},
    'Bell.tom-high': {},
    'Key.tom-low': {},
    'Galaxian.kick1': {},
    'Apple.kick2': {},
    'Melon.snare': {},
    'Orange.rim': {}
  },

  Effects: {
    extend: Flex,
    props: {
      flex: 1,
      alignItems: 'center',
      gridColumnStart: 'span 2',
      flexDirection: 'column',
      padding: 'B C',
      '@tabletS': {
        gridColumnStart: 'span 5',
        gridRowStart: '5',
        padding: 'B B D'
      }
    },

    Text: {
      margin: 'X -B B1',
      textAlign: 'center',
      opacity: '.55',
      fontFamily: 'game',
      padding: 'Z2',
      width: '85%',
      background: 'white 0.15',
      text: '0'
    },

    Grid: {
      props: {
        columns: 'repeat(3, 1fr)',
        gap: 'D+X',
        '@tabletS': {
          gap: 'D3'
        },
        '@mobileS': {
          gap: 'E1'
        }
      },
      childExtend: 'Spinner',
      ...[{
        Text: { text: 'Freq' }
      }, {
        Text: { text: 'Res' }
      }, {
        Text: { text: 'Amp' }
      }, {
        Text: { text: 'Delay' }
      }, {
        Text: { text: 'Feedback' }
      }, {
        Text: { text: 'Filter' }
      }, {
        Text: { text: 'Reverb' }
      }, {
        Text: { text: 'Decay' }
      }, {
        Text: { text: 'Pan' }
      }]
    }
  },

  StepSequencer: {
    extend: 'Grid',

    props: {
      overflow: 'hidden',
      round: 'Y',
      gap: 'B1+Z',
      align: 'center',
      padding: 'B B B D1',
      position: 'relative',
      columns: 'repeat(16, 1fr)',
      gridColumnStart: 'span 5',

      '@tabletS': {
        padding: 'B B2 B D1-C',
        columns: 'repeat(8, 1fr)'
      }
    },

    childExtend: {
      props: {
        class: 'sequencer-step',
        position: 'relative',
        background: '#f1bcb2',
        boxSize: 'Y',
        cursor: 'pointer',
        round: 'V',
        '&.active div': {
          display: 'block'
        },
        ':after': {
          content: '""',
          background: 'white .05',
          boxSize: 'C',
          position: 'absolute',
          top: '50%',
          round: 'C1',
          opacity: 0,
          transition: 'opacity, defaultBezier, A',
          left: '50%',
          transform: 'translate3d(-50%, -50%, 1px)'
        },
        ':hover': {
          ':after': {
            opacity: 1
          }
        }
      }
    },

    Pacman: {
      ignoreChildExtend: true,
      position: 'absolute',
      id: 'pacman',
      top: 'Z',
      left: 'B2',
      borderRadius: '0',
      transform: 'translateX(-20px)',
      zIndex: 2,
      transition: 'A left linear',
      margin: '- C - -',
      '@tabletS': {
        left: 0
      },

      ':after': {
        content: '""',
        background: 'black',
        top: '0',
        bottom: '0',
        position: 'absolute',
        right: '100%',
        width: '100vw'
      }
    },

    step1: {},
    step2: {},
    step3: {},
    step4: {},
    step5: {},
    step6: {},
    step7: {},
    step8: {},
    step9: {},
    step10: {},
    step11: {},
    step12: {},
    step13: {},
    step14: {},
    step15: {},
    step16: {}
  },

  Footer: {
    extend: 'Flex',

    props: {
      border: '',
      gridColumnStart: 'span 5',
      color: 'white',
      padding: 'A 0',
      align: 'center space-between'
    },

    MainControls: {
      extend: Flex,
      props: {
        margin: '- - - -Z2'
      },
      childExtend: {
        extend: 'SquareButton',
        props: {
          fontSize: 'C1',
          padding: 'Y',
          color: 'white',
          background: 'transparent',
          cursor: 'pointer'
        }
      },
      Play: {
        props: ({ state }) => ({
          class: 'play-button',
          icon: 'play',
          hide: state.playing,

          onClick: (ev, el, s) => {
            s.update({ playing: true })
          }
        })
      },
      Stop: {
        props: ({ state }) => ({
          class: 'stop-button',
          icon: 'pause',
          hide: !state.playing,
          onClick: (ev, el, s) => {
            s.update({ playing: false })
          }
        })
      },
      Clear: {
        class: 'clear-button',
        boxSize: 'B1',
        fontSize: 'A1',
        icon: null,
        text: 'X'
      }
    },

    Flex: {
      props: { gap: 'B', align: 'center' },
      Pass: {
        props: ({ state }) => ({
          cursor: 'pointer',
          text: (state.pass === 'low' ? 'L' : 'H') + '-pass',
          onClick: (ev, el, s) =>
            s.update({
              pass: s.pass === 'low' ? 'high' : 'low'
            })
        })
      },
      Sep: '|',
      Bpm: {
        extend: 'Flex',
        props: { gap: 'A', align: 'center' },
        Spinner: {},
        Text: {
          fontFamily: 'game',
          text: 0 + 'BPM'
        }
      }
    }
  },

  on: {
    init: (el, s) => {
      el.__ref.__root.data.setup.push(DrumMachine.setup)
    }
  }
}
